import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Cookies from 'js-cookie';

export function isAuthenticated(): boolean {
  if (getAuthToken()) {
    return true;
  }
  return false;
}

export function getAuthToken(): string | undefined {
  return Cookies.get("AuthToken");
}

export function setAuthToken(token: string, expiresInDays?: number): void {
  removeAuthToken();
  if (token) {
    Cookies.set("AuthToken", token, {
      expires: expiresInDays, // Optional expiration
      secure: process.env.NODE_ENV === "production",
      sameSite: "Strict",
    });
  }
}

export function removeAuthToken(): void {
  Cookies.remove("AuthToken", { path: "/" });
}

export function getLeadToken(): string | undefined {
  return Cookies.get("LeadToken");
}

export function setLeadToken(token: string, expiresInDays?: number): void {
  removeLeadToken();
  if (token) {
    Cookies.set("LeadToken", token, {
      expires: expiresInDays, // Optional expiration
      secure: process.env.NODE_ENV === "production",
      sameSite: "Strict",
    });
  }
}

export function removeLeadToken(): void {
  Cookies.remove("LeadToken", { path: "/" });
}

export function routeGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  const excludedPaths = [
    "questionnaire-info",
    "questionnaire-evaluation",
    "investment-questionnaire",
    "update-thank-you",
  ];
  const currentPath = to.path;

  if (excludedPaths.some((path) => currentPath.includes(path))) {
    next(); // Bypass authentication check for excluded paths
  } else {
    if (isAuthenticated()) {
      if (to.name === "Questionnaire" && from.name !== "QuestionnaireInfo") {
        next({ name: "QuestionnaireInfo" });
      } else if (
        to.name === "QuestionnaireEvaluation" &&
        from.name !== "Questionnaire"
      ) {
        next({ name: "QuestionnaireInfo" });
      } else if (
        to.name === "ChildPersonalInfo" &&
        from.name !== "PersonalInfo"
      ) {
        next({ name: "PersonalInfo" });
      } else {
        next(); // Allow to enter route
      }
    } else {
      next("/"); // Go to landing page
    }
  }
  window.scrollTo(0, 0);
}
